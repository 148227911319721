import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CordovaService } from '@swan/lib/cordova';
import { SwanConfigModule } from '@swan/services/config';
import { SwanModule } from '@swan/swan.module';
import { MockApiModule, setAppInjector } from '@yukawa/chain-base-angular-client';
import { AppRoutingModule } from 'app/app-routing.module';
import { AppComponent } from 'app/app.component';
import { appConfig } from 'app/core/config/app.config';
import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { mockApiServices } from 'app/mock-api';
import { MarkdownModule } from 'ngx-markdown';
import { MatFormFieldModule } from '@angular/material/form-field';


@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,

        // Swan, SwanConfig & SwanMockAPI
        SwanModule,
        SwanConfigModule.forRoot(appConfig),
        MockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),
    ],
    providers   : [
        CordovaService,
    ],
    bootstrap   : [
        AppComponent,
    ],
})
export class AppModule
{
    constructor(injector: Injector)
    {
        setAppInjector(injector);
    }
}
