import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { EventArgs, StaticConstructable } from '@awesome-nodes/object';
import { ContentVideoFile, LikedService, PlayerPositionService, VideoList, VideoListTypes } from '@swan/lib/content';
import { ContentFilter, ContentStreamType } from '@swan/lib/domain';
import { InterestInfoService, ProfileFollowService, Role, UserService } from '@swan/lib/profile';
import {
    AppInjector,
    ArgumentException,
    EventDelegate,
    Exception,
    ObjectModel,
} from '@yukawa/chain-base-angular-client';
import { lastValueFrom, Subscription } from 'rxjs';
import { Nullable } from 'simplytyped';
import { SwanFavoritesVideoList } from './swan-favorites-video-list.model';
import { SwanVideoFile } from './swan-video-file.entity';
import { SwanVideoList } from './swan-video-list.model';


export interface VideoListItemConfig
{
    showProgress?: boolean;
    showAuthor?: boolean;
    showProfilePicture?: boolean;
    showFavorite?: boolean;
    showPlayButton?: boolean;
    showTags?: boolean;
    showFollow?: boolean;
    showTitle?: boolean;
    showSelect?: boolean;
    select?: (checked: boolean, item: ContentVideoFile) => Promise<boolean>;
}

export interface VideoListConfig extends VideoListItemConfig
{
    title: string;
    sliderClass: string;
}

export class LoadEventArgs extends EventArgs
{
    #loadEvents = new Array<Promise<void>>();

    public constructor()
    {
        super();
    }

    public get loadEvents(): Array<Promise<void>>
    {
        return this.#loadEvents;
    }
}

export class ShowEventArgs extends EventArgs
{
    public constructor(
        private readonly _state: 'more' | 'less',
        private readonly _videoList?: SwanVideoList,
    )
    {
        super();
    }

    public get state(): 'more' | 'less'
    {
        return this._state;
    }

    public get videoList(): Nullable<SwanVideoList>
    {
        return this._videoList;
    }
}

export class VideoFileEventArgs extends EventArgs
{
    #updateEvents = new Array<Promise<void>>();

    public constructor(
        private _videoFile: SwanVideoFile,
        private _videoList: SwanVideoList,
    )
    {
        super();
    }

    public get videoFile(): SwanVideoFile
    {
        return this._videoFile;
    }

    public get videoList(): SwanVideoList
    {
        return this._videoList;
    }

    public get updateEvents(): Array<Promise<unknown>>
    {
        return this.#updateEvents;
    }
}

@Injectable()
@StaticConstructable
export class SwanVideoListService extends ObjectModel implements OnDestroy
{
    private static readonly _defaultVideoLists = new Map<VideoListTypes, VideoListConfig>();
    private static _favoritesVideoList: SwanFavoritesVideoList;
    private static _load: EventDelegate<SwanVideoListService, LoadEventArgs>;
    private static _videoFileUpdated: EventDelegate<SwanVideoListService, VideoFileEventArgs>;
    private static _videoFileDeleted: EventDelegate<SwanVideoListService, VideoFileEventArgs>;

    #loadSubscription!: Subscription;
    #videoFileUpdatedSubscription: Subscription;
    #videoFileDeletedSubscription!: Subscription;

    private readonly _show   = new EventDelegate<SwanVideoListService, ShowEventArgs>(this);
    private readonly _loaded = new EventDelegate<SwanVideoListService>(this);

    private readonly _videoLists       = new Map<VideoListTypes, SwanVideoList>();
    private readonly _videoListConfigs = new Map<VideoListTypes, VideoListConfig>();
    private readonly _videoListGroups  = new Map<VideoListTypes, Array<Array<SwanVideoFile>>>();
    private readonly _lastViewed       = new Map<VideoListTypes, SwanVideoFile>();

    private _videoList: Nullable<SwanVideoList>;
    private readonly _videoListDetail = new Map<VideoListTypes, SwanVideoList>();
    private readonly _suspendEvents   = new EventEmitter<boolean>();

    public constructor()
    {
        super();

        this.#loadSubscription             = SwanVideoListService._load.subscribe((sender, ea) =>
        {
            ea?.loadEvents.push(this.load());
        });
        this.#videoFileUpdatedSubscription = SwanVideoListService._videoFileUpdated
            .subscribe(this.videoFileUpdated.bind(this));
        this.#videoFileDeletedSubscription = SwanVideoListService._videoFileDeleted
            .subscribe(this.videoFileDeleted.bind(this));
    }

    public static get defaultVideoLists(): Map<VideoListTypes, VideoListConfig>
    {
        return this._defaultVideoLists;
    }

    public static get favoritesVideoList(): SwanFavoritesVideoList
    {
        return this._favoritesVideoList;
    }

    public static get videoFileDeleted(): EventDelegate<SwanVideoListService, VideoFileEventArgs>
    {
        return this._videoFileDeleted;
    }

    public get defaultListsAvailable(): boolean
    {
        for (const _listType of SwanVideoListService.defaultVideoLists.keys()) {
            if (!this._videoLists.has(_listType)) {
                return false;
            }
        }
        return true;
    }

    public get videoList(): Nullable<SwanVideoList>
    {
        return this._videoList;
    }

    public get videoLists(): Map<VideoListTypes, VideoList>
    {
        return this._videoList ? this._videoListDetail : this._videoLists;
    }

    public get videoListConfigs(): Map<VideoListTypes, VideoListConfig>
    {
        return this._videoListConfigs;
    }

    public get videoListGroups(): Map<VideoListTypes, Array<Array<ContentVideoFile>>>
    {
        return this._videoListGroups;
    }

    public get lastViewed(): Map<VideoListTypes, ContentVideoFile>
    {
        return this._lastViewed;
    }

    public get suspendEvents(): EventEmitter<boolean>
    {
        return this._suspendEvents;
    }

    public get show(): EventDelegate<SwanVideoListService, ShowEventArgs>
    {
        return this._show;
    }

    public get loaded(): EventDelegate<SwanVideoListService>
    {
        return this._loaded;
    }

    public static construct(): void
    {
        this._load             = new EventDelegate(null as never as SwanVideoListService);
        this._videoFileUpdated = new EventDelegate(null as never as SwanVideoListService);
        this._videoFileDeleted = new EventDelegate(null as never as SwanVideoListService);

        this._defaultVideoLists.set(VideoListTypes.followed, {
            title       : 'HOME.NEW_FROM_CHANNEL',
            showProgress: true,
            sliderClass : 'favs videowithprogress',
        });

        /*this._defaultVideoLists.set(VideoListTypes.watchlist, {
            title       : 'HOME.YOUR_WATCHLIST',
            showProgress: true,
            sliderClass : 'favs videowithprogress',
        });*/

        this._defaultVideoLists.set(VideoListTypes.favorites, {
            // title       : 'HOME.MY_FAVORITES',
            title       : 'HOME.YOUR_WATCHLIST',
            sliderClass : 'favs',
            showProgress: true,
            showFavorite: false,
        });

        this._defaultVideoLists.set(VideoListTypes.trending, {
                title         : 'HOME.TRENDING',
                sliderClass   : 'favs',
                showPlayButton: false,
                showProgress  : true,
            },
        );
    }

    public static async reset(): Promise<void>
    {
        AppInjector.get(PlayerPositionService).repository.clear();
        AppInjector.get(LikedService).repository.clear();
        AppInjector.get(ProfileFollowService).repository.clear();
        if (this._favoritesVideoList != null) {
            this._favoritesVideoList.items.length = 0;
        }

        const ea = new LoadEventArgs();
        this._load.invoke(ea);
        await Promise.all(ea.loadEvents);
    }

    public ngOnDestroy(): void
    {
        this.#loadSubscription.unsubscribe();
        this.#videoFileUpdatedSubscription.unsubscribe();
        this.#videoFileDeletedSubscription.unsubscribe();
    }

    public addItemList(
        listType: VideoListTypes,
        videoListConfig: VideoListConfig = SwanVideoListService.defaultVideoLists.get(listType) as VideoListConfig,
        filter?: ContentFilter | VideoList): SwanVideoList
    {
        let contentStreamType: ContentStreamType | undefined;

        switch (listType) {
            case VideoListTypes.trending:
                contentStreamType = ContentStreamType.TRENDING;
                break;
            case VideoListTypes.watchlist:
                contentStreamType = ContentStreamType.VIEWED;
                break;
            case VideoListTypes.favorites:
                contentStreamType = ContentStreamType.FAVORITE;
                break;
            case VideoListTypes.recommended:
                contentStreamType = ContentStreamType.RECOMMENDED;
                break;
            case VideoListTypes.related:
                contentStreamType = ContentStreamType.RELATED;
                break;
            case VideoListTypes.followed:
                contentStreamType = ContentStreamType.FOLLOWED;
                break;
        }

        if (SwanVideoListService._favoritesVideoList == null) {
            SwanVideoListService._favoritesVideoList = new SwanFavoritesVideoList(AppInjector);
        }

        this.videoLists.set(
            listType,
            filter instanceof VideoList
                ? filter
                : new SwanVideoList(
                    AppInjector,
                    listType,
                    SwanVideoListService._favoritesVideoList,
                    {
                        ...filter,
                        stream: contentStreamType,
                    },
                ),
        );
        this._videoListConfigs.set(listType, videoListConfig);

        return this.videoLists.get(listType) as SwanVideoList;
    }

    public groupVideoFiles(
        listType: VideoListTypes,
        groupSize: number,
    ): void
    {
        const videoList = this._videoLists.get(listType);
        if (!videoList) {
            throw new VideoListNotFoundException(listType, 'listType');
        }

        const groups = new Array<Array<SwanVideoFile>>();

        for (let i = 0; i < videoList.items.length; i += groupSize) {
            groups.push(videoList.items.slice(i, i + groupSize));
        }

        this._videoListGroups.clear();
        this._videoListGroups.set(listType, groups);
    }

    public showMore(listType: VideoListTypes): void
    {
        this._videoList = this._videoLists.get(listType);
        if (!this._videoList) {
            throw new VideoListNotFoundException(listType, 'listType');
        }
        this._videoListDetail.clear();
        this._videoListDetail.set(listType, this._videoList);
        this._show.invoke(new ShowEventArgs('more', this._videoList));
    }

    public showLess(listType: VideoListTypes): void
    {
        this._videoList = this._videoLists.get(listType);
        if (!this._videoList) {
            throw new VideoListNotFoundException(listType, 'listType');
        }
        this._videoList = null;
        this._videoListDetail.clear();
        this._show.invoke(new ShowEventArgs('less'));
    }

    public async load(...videoListTypes: Array<VideoListTypes>): Promise<void>
    {
        await lastValueFrom(AppInjector.get(LikedService).all());
        if (AppInjector.get(UserService).hasRole(Role.interestEdit)) {
            await AppInjector.get(InterestInfoService).loadInterests();
        }

        for (const _videoListType of this.videoLists.keys()) {
            if (videoListTypes.length > 0 && videoListTypes.indexOf(_videoListType) === -1) {
                continue;
            }
            const videoList = this.videoLists.get(_videoListType) as SwanVideoList;
            this.lastViewed.delete(_videoListType);
            if (_videoListType !== VideoListTypes.favorites) {
                videoList.reset();
            }
            await videoList.load();
        }
        this._loaded.invoke();
    }

    public async update(listType: VideoListTypes, videoFile: SwanVideoFile): Promise<void>
    {
        const videoList = this._videoLists.get(listType);

        if (!videoList) {
            throw new VideoListNotFoundException(listType, 'listType');
        }

        const ea = new VideoFileEventArgs(videoFile, videoList);
        SwanVideoListService._videoFileUpdated.invoke(ea);
        await Promise.all(ea.updateEvents);
    }

    public async delete(listType: VideoListTypes, videoFile: SwanVideoFile): Promise<void>
    {
        const videoList = this._videoLists.get(listType);

        if (!videoList) {
            throw new VideoListNotFoundException(listType, 'listType');
        }

        await videoList.delete(videoFile);
        SwanVideoListService._videoFileDeleted.invoke(new VideoFileEventArgs(videoFile, videoList));
    }

    private* locateVideoFiles(ea: VideoFileEventArgs): Iterable<{ list: SwanVideoList; file: SwanVideoFile }>
    {
        for (const _listType of this.videoLists.keys()) {
            const videoList = this.videoLists.get(_listType) as SwanVideoList;
            const videoFile = videoList.items.find(item => item.id === ea.videoFile.id);
            if (videoFile) {
                yield { list: videoList, file: videoFile };
            }
        }
    }

    private async videoFileUpdated(sender: SwanVideoListService, ea?: VideoFileEventArgs): Promise<void>
    {
        for (const _video of this.locateVideoFiles(ea as VideoFileEventArgs)) {
            ea?.updateEvents.push(_video.list.reload(_video.file));
        }
    }

    private videoFileDeleted(sender: SwanVideoListService, ea?: VideoFileEventArgs): void
    {
        // Locate video file and remove from lists
        for (const _video of this.locateVideoFiles(ea as VideoFileEventArgs)) {
            const index = _video.list.items.indexOf(_video.file);
            _video.list.items.splice(index, 1);

            // Preserve existing view state by selecting previous element
            const swanVideoFile = this.lastViewed.get(_video.list.type);
            if (swanVideoFile != null && swanVideoFile.id === _video.file.id && index > 0) {
                this.lastViewed.set(_video.list.type, _video.list.items[index - 1]);
            }
        }
    }
}

export class VideoListNotFoundException extends ArgumentException
{
    public constructor(listType: VideoListTypes, paramName: string | undefined, innerException?: Exception)
    {
        super(`Video list for list type '${listType}' not set.`, paramName, innerException);

        ArgumentException.setPrototype(this);
    }
}
